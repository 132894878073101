import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react'
import TableDragSelect from 'react-table-drag-select';
import { useTableDragSelect } from "use-table-drag-select";
import {PageView, HeaderView} from '../../components/Pages/Views'
import NavBar from '../Nav'
import cogoToast from 'cogo-toast'
import moment from 'moment-timezone';
import {fetchAvailability, saveAvailability, getMaxInterviewsNum, setDefaultMaxInterviewsNum, setMaxInterviewsNum} from '../../services/availability'
import { useDispatch, useSelector } from 'react-redux';
import {availabilityAction} from '../../store/actions/Availability'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CustomModal from '../../components/Models/CustomModal';
import Button from '@material-ui/core/Button';
import{ useHistory } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from '@mui/material/Tooltip';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Select from '@mui/material/Select';
import './availability.scss';
import { useAvailabilityContext, useAvailabilityContextUpdate } from '../../availabilityContext'
import { profileAction } from '../../store/actions/Profile'
import useWindowDimension from "../../utilis/windowDimension"
import Fab from "@material-ui/core/Fab";
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import cx from "classnames";
import DefaultLoader from '../../components/Loader/DefaultLoader';
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2';

// Array for selecting num of max interviews
const maxNumOfInterviews = ["0","1","2","3","4","5","6","7","8","9","10"]

const calculateDaysArray = () => {
    let daysArray=[]
    for(let i=0;i<=7;i++){
        const date = new Date();
        date.setDate(date.getDate()+i);
        daysArray.push(date);
    }
    return daysArray;
}

const days = calculateDaysArray();

const Availability = (props) => {
    const { isEditing, link } = useAvailabilityContext();  
    const { changeIsEditing, changeLink } = useAvailabilityContextUpdate();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false)
    const dispatch = useDispatch();
    const historyRef = useHistory();
    const [cells, setCells] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));
    const timeZone = useSelector((state)=> state?.user?.user_config?.timezone);
    const [seletectedTimeZone , setSeletectedTimeZone] = useState(timeZone);
    const [enableSlot , setEnableSlot] = useState(false);
    const [cellsPayload,setCellsPayload] = useState(transposeArray(Array(31).fill().map(_ =>  Array(9).fill(true))));
    const [leaveModal,setLeaveModal] = useState(false);
    const profile = useSelector((state) => state?.profile)
    const [count, setCount] = useState('0');
    const [interviewDuration, setInterviewDuration] = useState({sixty: true, ninety: true, oneTwenty: true});
    const [childCounts, setChildCounts] = useState({}) // Map of "DD-MM-YYYY": "maxCount"
    const [originalChildCounts, setOriginalChildCounts] = useState({}); 
    const [countsFetched, setCountsFetched] = useState(false) // Tracks if counts have been fetched (first time only)
    const { width } = useWindowDimension()
    const [xAxisCurrentDir, setXAxisCurrentDir] = useState("left"); // State to track if user is seeing left/right of table (mobile screen only)
    const tableRef = useRef(null); // Ref to table (for scrolling)
    const [interviewerAvailability , setInterviewerAvailability] = useState(null);
    const [bookedCells, setBookedCells] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));
    const [isMaxCountLoaderVisible, setIsMaxCountLoaderVisible] = useState(false);
    const [mergeColumnData, setMergeColumnData] = useState(Array(31).fill().map(_ =>  Array(9).fill(1)));
    const [bookedMergeColumnData, setBookedMergeColumnData] = useState(Array(31).fill().map(_ =>  Array(9).fill(false)));
    const [filterAvailabeData, setFilterAvailabeData] = useState([]);
    const [maxedSlot, setMaxedSlot] = useState({});
    
    // State to track cells currently being dragged over
    const [draggingCells, setDraggingCells] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [startCell, setStartCell] = useState(null);
    const [draggableTableRef, draggableTableValue] = useTableDragSelect();

    const timeToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    const getValidAvailableSlots = (allAvailableSlots) => {
        let arr = [];
        if(allAvailableSlots && allAvailableSlots.length > 0) {
            allAvailableSlots?.forEach(item=>{
                const startTime = timeToMinutes(item.start_time);
                const endTime = timeToMinutes(item.end_time);
                const timeDifference = endTime - startTime;
                if(timeDifference > 30)
                    arr.push(item);
            });
        }
        return arr;    
    }

    const splitAvailableSlots = (allAvailableSlots) => {
        const currentDate = moment().format("DD-MM-YYYY");
        const currentTime = moment().format("HH:mm");
        const currentTimeInMinutes = timeToMinutes(currentTime);
        let arr = [];

        if(allAvailableSlots && allAvailableSlots.length > 0){
           allAvailableSlots.forEach(item=>{
            /* Current day's availability */
            if(item?.start_date == currentDate) {
                /* If current time is in middle */
                if(timeToMinutes(item?.end_time) < currentTimeInMinutes)
                {
                    item.isDisabled = true;
                    arr.push(item)
                }
                else if(timeToMinutes(item?.start_time) <= currentTimeInMinutes && timeToMinutes(item?.end_time) > currentTimeInMinutes){
                    /* first half of availability */
                    let obj1 = {...item}
                    obj1.start_time = item?.start_time;
                    let hour = (currentTime).split(":")[0];
                    let minute = (currentTime).split(":")[1];
                    if(+minute <= 30){
                        minute = "00";
                    }
                    else {
                        minute = "30";
                    }
                    let tempEndTime = hour+":"+minute;
                    obj1.end_time = tempEndTime;
                    obj1.isDisabled = true;
                    if(item?.start_time !== tempEndTime)
                    arr.push(obj1)


                    if(+minute >=30) {
                        minute = "00";
                        hour = (+hour+1).toString();
                    }
                    else {
                        minute = "30";
                    }
                    let tempStartTime = hour+":"+minute;

                    /* second half of availability */
                    let obj2 = {...item};
                    obj2.start_time = tempStartTime;
                    obj2.end_time = item?.end_time;
                    obj2.isDisabled = false;
                    arr.push(obj2)
                }
                else {
                    arr.push(item)
                }
            }
            else {
                arr.push(item)
            }
           });

        }
        return arr;
    }

    useEffect(()=> {
        const INTERVAL = 60 * 1000;

       let id =  setInterval(()=>{
        if(filterAvailabeData && filterAvailabeData.length > 0)
        {
            let data = splitAvailableSlots(filterAvailabeData);
            data = getValidAvailableSlots(data);
            setFilterAvailabeData(data);
            formatData(data, maxedSlot, childCounts);
        }
        },INTERVAL)
        return () => clearInterval(id);
    },[filterAvailabeData])

    const getFilterAvailableSlot = (data) => {
        if (!data || !data.original_slots) return;

        // Helper function to split a slot into available slots based on booked slots
        const splitSlot = (originalSlot) => {
            const availableSlots = [];
            let currentStart = timeToMinutes(originalSlot.start_time);
            const originalEnd = timeToMinutes(originalSlot.end_time);

            // Get all booked slots for the same date
            const bookedSlotsForDate = data.booked_slots
                .filter(bookedSlot => bookedSlot.start_date === originalSlot.start_date)
                .sort((a, b) => timeToMinutes(a.start_time) - timeToMinutes(b.start_time));

            // If no booked slots for this date, return the original slot
            if (bookedSlotsForDate.length === 0) {
                return [originalSlot];
            }

            // Process each booked slot
            for (const bookedSlot of bookedSlotsForDate) {
                const bookedStart = timeToMinutes(bookedSlot.start_time)-30;
                const bookedEnd = timeToMinutes(bookedSlot.end_time)+30;

                // Skip if booked slot is outside the original slot's time range
                if (bookedStart >= originalEnd || bookedEnd <= currentStart) {
                continue;
                }

                // If there's a gap before the booked slot, add it as an available slot
                if (currentStart < bookedStart) {
                availableSlots.push({
                    ...originalSlot,
                    start_time: minutesToTime(currentStart),
                    end_time: minutesToTime(bookedStart)
                });
                }

                // Update the current start time to after the booked slot
                currentStart = bookedEnd;
            }

            // If there's remaining time after the last booked slot, add it as an available slot
            if (currentStart < originalEnd) {
                availableSlots.push({
                ...originalSlot,
                start_time: minutesToTime(currentStart),
                end_time: minutesToTime(originalEnd)
                });
            }

            return availableSlots;
        };

        // Process all original slots
        let allAvailableSlots = data.original_slots.flatMap(slot => splitSlot(slot));
        allAvailableSlots = splitAvailableSlots(allAvailableSlots);
        allAvailableSlots = getValidAvailableSlots(allAvailableSlots);
        return allAvailableSlots;
    }
    
    // Sets default count and interview duration whenever profile changes
    useEffect(() => {
        if(profile){
            if(profile?.max_interviews_per_day){
                setCount(profile?.max_interviews_per_day?.toString())
            }
        }
    }, [profile])

    // Set child counts on load it gives you the date from current date to next 7 days date which show in the header section of the table
    useEffect(() => {
        (async () => {
            const fromDate = days[0]
            const toDate = new Date(days[days.length - 1])
            toDate.setDate(toDate.getDate() + 1)

            const res = await getMaxInterviewsNum(
                moment(fromDate).format("DD-MM-YYYY"),                
                moment(toDate).format("DD-MM-YYYY"),
            )
            if(res){
                const newChildCounts = {}
                Object.keys(res).forEach((newChildCountObj) => {
                    newChildCounts[newChildCountObj] = res[newChildCountObj].toString()
                })
                setChildCounts((prevChildCounts) => ({
                    ...prevChildCounts,
                    ...newChildCounts
                }))
                setOriginalChildCounts(JSON.parse(JSON.stringify(newChildCounts)))
                fetchData([], {...childCounts, ...newChildCounts});
            } else {
                fetchData();
            }
            setCountsFetched(true)
        })()
    }, [])

    useEffect(()=>{
        if(timeZone){
            setSeletectedTimeZone(timeZone)
        }
    },[timeZone])


    const fetchData = async (childCountVal) => {
        try{
            let payload ={
                timezone:seletectedTimeZone,
                duration: [60, 90, 120],
            }
            const res = await fetchAvailability(payload);
            setInterviewerAvailability(res)
            dispatch(availabilityAction(res))
            let tempMaxedSlot={};
            res?.booked_slots?.forEach((item, index)=>{
                if(!tempMaxedSlot[item?.start_date]){
                    tempMaxedSlot[item?.start_date] = 0;
                }
                tempMaxedSlot[item?.start_date]++;
            })
            const bookedSlotData = convertDataToCells(res?.booked_slots, childCountVal || childCounts, tempMaxedSlot, "booked");
            // It generates 2-D array of true and false
            // first row and first column we are not using in the table 
            setMaxedSlot(tempMaxedSlot);
            setBookedCells(transposeArray(bookedSlotData));
            getMergeColumnData(transposeArray(bookedSlotData), "booked")
            let filterAvailableSlot = getFilterAvailableSlot(res);
            setFilterAvailabeData(filterAvailableSlot);
            formatData(filterAvailableSlot, tempMaxedSlot, childCountVal || childCounts);
        } catch(err){
        }
    }

    const formatData = (data, tempMaxedSlot, childCountVal) => {
        const formattedData = convertDataToCells(data, childCountVal, tempMaxedSlot);
        const transposedCells = transposeArray(formattedData) 
        setCells(transposedCells)
        const formattMergedData = convertDataToCells(data, "merged");
        const transposedMergedCells = transposeArray(formattMergedData)
        getMergeColumnData(transposedMergedCells)
        setCellsPayload(transposeArray(transposedCells))
    }
    const changeCells = async (val) => {
        const transposedCells = transposeArray(val);
        if(!checkValiditiy(transposedCells.map(item => item.slice(1)))){
            cogoToast.error('Each slot should be at least one hour long')
            return;
        }
        if(!enableSlot){
            return;
        }
        getMergeColumnData(val);
        setCellsPayload(transposedCells);
        const newAvailability = days.map((item, index) => {
            return getAvailability(transposedCells[index+1].slice(1), 8).map(item2 => ({
                "recursive": "true",
                "start_time": item2.start,
                "end_time": item2.end,
                "from_date":moment(item).format('YYYY-MM-DD'),
                "to_date":moment(item).format('YYYY-MM-DD')
            }))
        }).flat()
        dispatch(availabilityAction(newAvailability))
    }

    // Handles sending individual max counts
    // For dates that don't have an explicit count sent, the default is sent.
    // This is to done to ensure that changing default max count would only change
    // counts for next week, and not the currently ongoing one.
    const updateMaxInterviewsCounts = async () => {
        const datesAndCountsList = days.map((dayDate) => {
            const dateKey = moment(dayDate).format("DD-MM-YYYY")
            return {
                "date": dateKey,
                "interviews_count": childCounts[dateKey] || count
            }
        })
        if(isChildCountUpdated()){
            await setMaxInterviewsNum(datesAndCountsList)
            setOriginalChildCounts(JSON.parse(JSON.stringify(childCounts)))
        }
    }

    const isChildCountUpdated = () => {
        let isUpadted = false;
        Object.entries(originalChildCounts)?.map(([date, count])=>{
          if(childCounts[date] !== count){
            isUpadted = true;
          }
        })
        return isUpadted;
    }

    const updateAvailability = async() =>{
        setIsLoaderVisible(true);
        const updatedAvailability = days.map((item, index) => {
            return getAvailability(cellsPayload[index+1].slice(1), 8).map(item2 => ({
                "recursive": "true",
                "start_time": item2.start,
                "end_time": item2.end,
                "start_date":moment(item).format('YYYY-MM-DD'),
                "end_date":moment(item).format('YYYY-MM-DD')
            }))
        }).flat()
        const payload ={
            timezone:seletectedTimeZone,
            slots:updatedAvailability
        }
        try{
            await saveAvailability(payload);
            await fetchData();
            setIsLoaderVisible(false);
        }
        catch(error){
            setIsLoaderVisible(false);
        }
    }

    const handleSaveButtonClick = async () => {

        setInterviewDuration({sixty: false, ninety: false, oneTwenty: false});
        await fetchData([]);

        if(enableSlot){
            setIsLoaderVisible(true);
            try{
                await updateMaxInterviewsCounts()
                await updateAvailability();
                changeIsEditing(false);
                setIsLoaderVisible(false);
            }
            catch(error){
                setIsLoaderVisible(false);
            }
        }else{
            changeIsEditing(true);
        }
       setEnableSlot(!enableSlot);
    }

    useEffect(()=>{
        if(isEditing){
            setLeaveModal(true);
        }else{
            setLeaveModal(false);
        }
    },[link])

    // Handles default max interviews change
    const handleChange = async (event) => {
        const newDefaultMaxInterviewsNum = event.target.value

        setIsMaxCountLoaderVisible(true);
        // Send new default max to backend, and update new profile received in reply
        const updatedProfile = await setDefaultMaxInterviewsNum(profile.last_updated_timestamp, newDefaultMaxInterviewsNum)
        dispatch(profileAction(updatedProfile));
        setIsMaxCountLoaderVisible(false)
    };

    // Handles child max interviews change
    const handleChildChange = (date, event) => {
        // Since the draggable table library does not allow the dropdown to maintain state in edit/non-edit mode, 
        // we are calling edit availability function whenever the interviewer tries to change the dropdown
        const maxInterviews = event.target.value
        setChildCounts((prevChildCounts) => ({
            ...prevChildCounts,
            [moment(date).format("DD-MM-YYYY")]: maxInterviews
        }))
    }

    // Handles interviews duration change
    const handleDurationChange = async (event) => {
        const {name, checked} = event?.target;
        let tempDuration = {...interviewDuration};
        tempDuration[name] = checked;
        setInterviewDuration(tempDuration);
        let durationArr = [];
        if(tempDuration?.sixty) durationArr.push(60);
        if(tempDuration?.ninety) durationArr.push(90);
        if(tempDuration?.oneTwenty) durationArr.push(120);
    };
      
    const renderDropdownParent= () => {
        return(
            <Box sx={{ maxWidth: 30 }} className="parent-max-count-wrapper">
                <FormControl fullWidth>
                  <Select
                    className='parent-max-count'
                    id="demo-simple-select"
                    sx={{"& legend": { display: "none" }}}
                    MenuProps={{
                      PaperProps:{
                        sx: {
                            maxHeight: '60%',
                            "&::-webkit-scrollbar": {
                                width: "2px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#A2A6AF", 
                                borderRadius: "4px",
                            },
                        }},
                    }}
                    value={count}
                    onChange={handleChange}
                    variant="outlined"           
                  >
                    {maxNumOfInterviews.map((num) => (
                        <MenuItem value={num} key={num} sx={{fontSize:'14px'}}>{num}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </Box>
        )
    }

    const renderCheckbox = (name, label, checked) => {
        return(
            <Box className="duration-wrapper">
                <FormControlLabel
                    control={
                    <Checkbox
                        name={name}
                        color="primary"
                        checked={checked}
                        disabled={isEditing}
                        className='checkbox-container'
                        onChange={handleDurationChange}
                    />
                    }
                    label={<Box className='checkbox-text'>{label}</Box>}
                />
            </Box>
        )
    }

    const handleDisableClick = async () => {
        Swal.fire({
            icon: 'error',
            html:'Click on <b>Edit Availability</b>,button to update selective max counts.',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3500
          })
    }

    const renderDropdown= (date, index) => {
        return(
            <Box key={index} sx={{ maxWidth: 50 }} className={"child-max-count-wrapper"}>
                {countsFetched &&
                    <FormControl fullWidth>
                        <Select
                          className='child-max-count'
                          id="demo-simple-select"
                          value={childCounts[moment(date).format("DD-MM-YYYY")] || count }
                          onChange={(e) => { handleChildChange(date, e) }}
                          variant="standard"
                          MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            disablePortal: true,
                            PaperProps:{
                                sx: {
                                    maxHeight: '32%',
                                    "&::-webkit-scrollbar": {
                                        width: "2px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#A2A6AF", 
                                        borderRadius: "4px",
                                    },
                                }},
                            }}
                          onClick={!enableSlot ? handleDisableClick : ()=> {}}
                          disabled={!enableSlot}
                        >
                          {maxNumOfInterviews.map((num) => (
                              <MenuItem value={num} key={num} sx={{fontSize:'14px'}}>{num}</MenuItem>
                          ))}
                        </Select>
                    </FormControl>
                }
            </Box>
        )
    }

    // Performs table scroll (x-axis)
    useEffect(() => {
        if(tableRef?.current){
            if(xAxisCurrentDir === 'left'){ // Scroll left
                tableRef?.current?.scrollBy({ left: -window.innerWidth, behavior: "smooth" });
            } else { // Scroll right
                tableRef?.current?.scrollBy({ left: window.innerWidth, behavior: "smooth" });
            }
        }
    }, [xAxisCurrentDir, tableRef])

    // Function to handle Y-axis FAB click
    const handleYFabClick = useCallback((direction) => {
        if (direction === 'up'){ // Scroll up
            window.scrollBy({ top: -400, behavior: "smooth" })
        } else { // Scroll down
            window.scrollBy({ top: 400, behavior: "smooth" })
        }
    }, [])

    const handleMouseClick = (item, rowIndex, columnIndex) => {
        let cellData = cells[rowIndex][columnIndex];
        let isSelected = (typeof cellData === 'object' && item !== null) || cellData === true ? true: false;
        if(isEditing) {
            if(isSelected) {
                const newCells = JSON.parse(JSON.stringify(cells));
                let i = rowIndex;
                while(i<=30 && ((typeof newCells[i][columnIndex] === 'object' && newCells[i][columnIndex]  !== null) || newCells[i][columnIndex] === true ) )
                {
                    newCells[i][columnIndex] = false;
                    i++;
                }
                setCells(newCells);
                changeCells(newCells);
            }

        }
        if(isEditing && !isSelected && !isDragging){
            cogoToast.error('Each slot should be at least one hour long')
            return;
        }
    }

     // Handle mouse events to track dragging
     const handleMouseDown = (item,rowIndex, columnIndex) => {
        if(item[columnIndex]?.data?.isDisabled || !isEditing) 
            return;

        setStartCell({ rowIndex, columnIndex });
        setDraggingCells([{ rowIndex, columnIndex }]);
        setIsDragging(true);
    };

    // Debounce function to limit the rate of state updates
    const debounce = (func, delay) => {
        let debounceTimer;
        return function(...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleMouseMove = useCallback(debounce((item, rowIndex, columnIndex) => {
        if(item[columnIndex]?.data?.isDisabled || !isEditing) 
            return;

        if (isDragging && startCell) {
          const minRow = Math.max(Math.min(startCell.rowIndex, rowIndex), 1);
          const maxRow = Math.min(Math.max(startCell.rowIndex, rowIndex), 30);
          const minCol = Math.max(Math.min(startCell.columnIndex, columnIndex), 1);
          const maxCol = Math.min(Math.max(startCell.columnIndex, columnIndex), 8);
          const startCellValue = cells[minRow][minCol];

          const newCells = cells.map((row, rIndex) =>
            row.map((cell, cIndex) =>
              (rIndex >= minRow && rIndex <= maxRow && cIndex >= minCol && cIndex <= maxCol)
                ? !startCellValue // Toggle the cell value within the drag range
                : cell
            )
          );

          let isValid = true;
          const transposedCells = transposeArray(newCells);
            if(!checkValiditiy(transposedCells.map(item => item.slice(1)))){
                isValid = false;
                return;
            }
        
            if(isValid) {
                setCells(newCells);
            }

          setDraggingCells(newCells.map((row, rIndex) =>
            row.map((cell, cIndex) =>
              (rIndex >= minRow && rIndex <= maxRow && cIndex >= minCol && cIndex <= maxCol)
                ? { rowIndex: rIndex, columnIndex: cIndex }
                : null
            ).filter(Boolean)
          ).flat());

        }
      },100),[isDragging, startCell]) // Adjust the delay as needed      

      const handleMouseUp = () => {
        setIsDragging(false);
        setDraggingCells([]);
        setStartCell(null);
        changeCells(cells);
      };

    // Function to check if a cell is being dragged
    const isCellDragging = (rowIndex, columnIndex) => {
        return draggingCells.some(
        (dragCell) => dragCell.rowIndex === rowIndex && dragCell.columnIndex === columnIndex
        );
    };

    const getTimeStamp2 = (date, time) => {
        const dateTime = `${date} ${time}`;
        // Convert to timestamp (Unix timestamp in milliseconds)
        const timestamp = moment(dateTime, "YYYY-MM-DD HH:mm:ss").valueOf();
        return timestamp;
    }

    const getTimeStamp = (date, time) => {
        const dateTime = `${date} ${time}`;
        // Correct format for parsing
        const timestamp = moment(dateTime, "DD-MM-YYYY HH:mm").local().valueOf();
        return timestamp;
    };

    const renderAvailableSlots = (data) => {
        let startTimestamp = getTimeStamp(data?.start_date, data?.start_time);
        let endTimestamp = getTimeStamp(data?.end_date, data?.end_time);
        return <Box className='slot-text'>
            Available slots
            <Box>{moment(startTimestamp)?.format("hh:mma")} - {moment(endTimestamp)?.format("hh:mma")}</Box>
        </Box>
    }

    const bookedSlotDetails = (data) => {
        let startTimestamp = getTimeStamp(data?.start_date, data?.start_time);
        let endTimestamp = getTimeStamp(data?.end_date, data?.end_time);
        return <Box padding='10px 16px' fontSize='12px' fontWeight='400' fontFamily='Poppins'>
            <Box fontWeight='500'>{data?.info?.round_name}</Box>
            <Box display='flex' gap='2px' alignItems='center'fontWeight='400'>
            {moment(data?.start_date, "DD-MM-YYYY")?.format("ddd, MMM DD")} | {moment(startTimestamp)?.format("hh:mma")} - {moment(endTimestamp)?.format("hh:mma")} - {data?.duration} mins 
            </Box>
            <Box mt="10px" display="flex" gap="10px" fontWeight='400'>
                Client: &nbsp;<Box fontWeight='500'>{data?.info?.client_name}</Box>
            </Box>
            <Box display="flex" gap="10px" fontWeight='400'>
                Position: &nbsp;<Box fontWeight='500'>{data?.info?.position_name}</Box>
            </Box>
        </Box>
    }

    const RenderOngoingSlots = ({ data }) => { 
        let startTimestamp = getTimeStamp(data?.start_date, data?.start_time); 
        let endTimestamp = getTimeStamp(data?.end_date, data?.end_time); 
        let duration = moment.duration(endTimestamp - startTimestamp).asMinutes();
        const [progress, setProgress] = useState(0);
                
        useEffect(() => {
            if (!startTimestamp || !endTimestamp) return;
            let interval;
    
            const updateProgress = () => {
                const currentTime = moment().tz("Asia/Kolkata").valueOf();
    
                if (currentTime >= endTimestamp) {
                    setProgress(101);
                    clearInterval(interval);
                    return;
                }
                const percentage = ((currentTime - startTimestamp) / (endTimestamp - startTimestamp)) * 100;
                setProgress(Math.min(Math.max(percentage, 0), 100));
            };
    
            updateProgress(); // Initial update
            interval = setInterval(updateProgress, 60000); // Update every minute
    
            return () => clearInterval(interval);
        }, [startTimestamp, endTimestamp]);
            
        return <Box height="100%" display="flex" alignItems="center" justifyContent="center" position="relative"> 
            <Tooltip 
                title={bookedSlotDetails(data)} placement="right" 
                componentsProps={{ 
                    tooltip: { 
                        sx: { 
                            color: "#3D3D3D", 
                            backgroundColor: "#ffff",
                            fontSize: "14px", 
                            minWidth: "320px", 
                            borderRadius: "8px", 
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", 
                        }, 
                    }, 
                    }} 
                > 
                <Box height="100%" padding="10px" className="booked-slot" width="100%" position="relative"> 
                    <Box>Ongoing Interview</Box> <Box>{duration} mins</Box> 
                    <Box>{moment(startTimestamp).format("hh:mma")} - {moment(endTimestamp).format("hh:mma")}</Box> 
                    {/* Red Line Indicator */} 
                    {progress<=100 &&
                        <Box position="absolute" top={`${progress}%`} left={0} width="100%" display='flex' alignItems='center'>
                            <Box height="8px" bgcolor="red" borderRadius="10px" width="6%"></Box>
                            <Box height="2px" bgcolor="red" width="93%"></Box>
                        </Box>
                    }
                </Box> 
            </Tooltip> 
        </Box>
    };

    const renderBookedSlots = (data) => {
        let startTimestamp = getTimeStamp(data?.start_date, data?.start_time);
        let endTimestamp = getTimeStamp(data?.end_date, data?.end_time);
        let duration = moment.duration(endTimestamp - startTimestamp).asMinutes();
        return <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Tooltip 
        title={bookedSlotDetails(data)}
        placement='right'
        componentsProps={{
            tooltip: {
              sx: {
                color: "#3D3D3D",
                backgroundColor: "#ffff",
                fontSize: "14px",
                minWidth: "320px",
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }
            }
          }}
        >
            <Box height="100%" padding="10px" className={data?.info?.interview_status === "SCHEDULED" ? 'booked-slot' : 'past-slot'}>
                Interview { data?.info?.interview_status === "COMPLETED" ? "Completed" 
                    : (data?.info?.interview_status === "CANCELLED" || data?.info?.interview_status === "DELETED") ? "Cancelled" 
                    : "Booked" }
                <Box>{duration} mins</Box>
                <Box>{moment(startTimestamp)?.format("hh:mma")} - {moment(endTimestamp)?.format("hh:mma")}</Box>
                <Box mt="5px">
                {data?.info?.interview_status ? 
                    data?.info?.interview_status === "COMPLETED" ? <TaskAltIcon sx={{color:'green', fontSize:'16px'}} /> :
                    (data?.info?.interview_status === "CANCELLED" || data?.interview_status === "DELETED") ?
                        <CancelOutlinedIcon sx={{color:'red', fontSize:'16px'}} /> : ''
                : ''}
                </Box>
            </Box>
        </Tooltip>
        </Box> 
    }
    const getMergeColumnData = (cellData, type) => {
        const newMergeColumnData = Array(31)
          .fill()
          .map(() => Array(9).fill(1)); // Initialize with rowSpan = 1 for each cell
      
        for (let col = 1; col < 9; col++) { // Skip first column (time column)
          let row = 1; // Skip first row (date heading)
          
          while (row < 31) {
            if (cellData[row][col]!==false) {
              let span = 1;
      
              // Find the continuous 'true' block
              while (row + span < 31 && cellData[row + span][col]!==false) {
                span++;
              }
      
              // Set rowSpan for the first cell
              if(span > 1)
              newMergeColumnData[row][col] = span;
      
              // Mark the merged rows as 0 to prevent rendering
              for (let i = row + 1; i < row + span; i++) {
                newMergeColumnData[i][col] = 0;
              }
      
              // Move to next unmerged row
              row += span;
            } else {
              row++;
            }
          }
        }
        
        if(type === "booked")
            setBookedMergeColumnData(newMergeColumnData)
        else
            setMergeColumnData(newMergeColumnData);
      }

      const getRowSpan = (rowIndex) => {
        return mergeColumnData[rowIndex];
      };

      const getBookedRowSpan = (rowIndex, colIndex) => {
        return bookedMergeColumnData[rowIndex][colIndex];
      }

    function isCurrentTimeInRange(startDate, startTime, endDate, endTime) {
        // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
        const [startDay, startMonth, startYear] = startDate.split("-");
        const [endDay, endMonth, endYear] = endDate.split("-");
        
        const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
        const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
    
        const startDateTime = new Date(`${formattedStartDate}T${startTime}:00`);
        const endDateTime = new Date(`${formattedEndDate}T${endTime}:00`);
    
        const now = new Date();

        return now >= startDateTime && now <= endDateTime;
    }

    const isDurationValid = (rowIndex, colIndex) => {
       let bookedDuration = bookedCells[rowIndex][colIndex]?.data?.duration;
       let isValid = false;
       if(!interviewDuration?.sixty && !interviewDuration?.ninety && !interviewDuration?.oneTwenty)
            isValid = true;
       if(bookedDuration === 60 && interviewDuration?.sixty)
            isValid = true;
        else if(bookedDuration === 90 && interviewDuration?.ninety)
            isValid = true;
        else if(bookedDuration === 120 && interviewDuration?.oneTwenty)
            isValid = true;
        return isValid;
    }

    const renderDraggableTable = () => {
        return (
            <div className="scrollable-table-wrapper">
            <table ref={draggableTableRef} className='table table-sm table-nowrap' id="availability-table">
            <thead >
                <tr>
                    <td className="date-day-max-count-wrapper" ></td>
                {
                    days.map((day, index)=>{
                        /* Below component is rendered out by a map. Since the value for each one of this may change for a number of reasons 
                        (individual change in 'childCounts' map, 'count' value, profile, and date) the key includes a concat of all factors that needs to trigger a re-render. Removing them WILL stop re-rendering. */
                        return <td 
                                className="date-day-max-count-wrapper" 
                                disabled key={`${day.getDate()}-${countsFetched}-${childCounts[moment(day).format('DD-MM-YYYY')]}-${count}-${enableSlot}`}>
                            <>
                            <Box className='fl-row fl-item-cntr'>
                                <Typography className="heading-date">{moment(day).format('MMM DD')}</Typography>
                                <Typography className="heading-day">&nbsp;({moment(day).format('ddd')})</Typography>
                            </Box>
                                <Box className="max-count-wrapper">
                                    <Box className="max-count-text">Max. :</Box>
                                    {renderDropdown(day, index)}
                                </Box>
                            </>
                        </td>
                    })
                }
                </tr>
            </thead>
            <tbody>
                {cells.slice(1).map((item, rowIndex) => {
                    const rowSpanArr = getRowSpan(rowIndex+1);
                    return (<tr key={rowIndex}>
                        <td className="time-column">{formatTableTime(rowIndex + 16)}</td>
                        {rowSpanArr.slice(1).map((rowSpan, colIndex) => {

                            let bookedSlotInTimeRange = false;
                            let slotData = bookedCells[rowIndex+1][colIndex+1]?.data;
                            if(slotData?.isBooked && slotData?.start_date && slotData?.start_time && slotData?.end_date && slotData?.end_time) {
                                bookedSlotInTimeRange = isCurrentTimeInRange(slotData?.start_date, slotData?.start_time, slotData?.end_date, slotData?.end_time);
                            }

                            let bookedRowSpan = getBookedRowSpan(rowIndex+1, colIndex+1);
                            
                            if(rowSpan !== 0 && bookedRowSpan !== 0)
                                return (
                                    <td
                                        key={`${rowIndex}-${colIndex}`}
                                        onClick={()=>handleMouseClick(item, rowIndex+1, colIndex+1)}
                                        onMouseDown={() => handleMouseDown(item, rowIndex+1, colIndex+1)}
                                        onMouseOver={() => handleMouseMove(item, rowIndex+1, colIndex+1)}
                                        onMouseUp={()=> handleMouseUp(rowIndex+1, colIndex+1)}
                                        rowSpan={bookedCells[rowIndex+1][colIndex+1]?.data  ? bookedRowSpan: rowSpan}
                                        className={`availability-cell`}
                                        disabled={bookedCells?.[rowIndex+1][colIndex+1]} 
                                    >
                                        <Box className={`cell-inner-wrapper
                                            ${bookedCells[rowIndex+1][colIndex+1]?.data ? 'cell-booked' : rowSpan > 1 ? (item[colIndex+1]?.data?.isMaxed || item[colIndex+1]?.data?.isDisabled )? 'grey-border maxed-slot-container' : 'blue-border' : ''}
                                            ${isCellDragging(rowIndex+1, colIndex+1) ? 'cell-being-selected': ''}
                                        `}>
                                            {bookedSlotInTimeRange && bookedCells[rowIndex+1][colIndex+1]?.data ? 
                                                <RenderOngoingSlots data={bookedCells[rowIndex+1][colIndex+1]?.data}/>
                                            : bookedCells[rowIndex+1][colIndex+1]?.data ? 
                                           isDurationValid(rowIndex+1, colIndex+1) && renderBookedSlots(bookedCells[rowIndex+1][colIndex+1]?.data) 
                                            : rowSpan > 1 && item[colIndex+1]?.data ?
                                                renderAvailableSlots(item[colIndex+1]?.data)
                                            : ''}
                                        </Box>
                                    </td>
                                )
                            })
                        }
                    </tr>)
                })}
            </tbody>
        </table>
        </div>
        )
      }
      
      return (
        <div className="Availability">
        <DefaultLoader isLoading={isLoaderVisible} text="Optimizing Your Availability Slots..."/>
            <NavBar {...props} pageTitle="Availability" augmentElement={
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                    <Button onClick={handleSaveButtonClick} color="primary" variant="contained">
                        {enableSlot ? `Save${width >= 375 ? " Availability": ""}`: `Edit${width >= 375 ? " Availability" : ""}`}
                    </Button>
                </Box>
            }/>
            <CustomModal
                open={leaveModal}
                onClose={()=>{setLeaveModal(false)}}
            >
                <Box className="leave-modal">
                    <Typography className="modal-description" >
                    Do you want to save your updated availability before moving to next page?
                    </Typography>
                    <Box className="modal-footer-btns">
                    <Button 
                        onClick={()=>{historyRef.push(link); changeIsEditing(false);}} 
                        className="leave-btn"
                        variant="outlined"
                        color="primary"
                    >
                        No,Thanks
                    </Button>
                    <Button 
                        onClick={async ()=>{
                            await Promise.all([
                                updateAvailability(),
                                updateMaxInterviewsCounts()
                            ])
                            historyRef.push(link);
                            changeIsEditing(false);
                        }} 
                        className="stay-btn"
                        variant="contained"
                        color="primary"
                    >
                        Save Availability
                    </Button>
                    </Box>
                </Box>
            </CustomModal>
            <PageView>
                <HeaderView title = 'Availability' OwnComp={<div className="fl-col headerComp">
                    <Box display='flex' alignSelf="flex-start">
                        <button className="btn-save" onClick ={handleSaveButtonClick}>
                            <span className="mr-2 ">{enableSlot ? 'Save Availability': 'Edit Availability'}</span>
                        </button>
                    </Box>
                </div>}/>
                {seletectedTimeZone ? <>
                <Box className="fl-row fl-sp-bw gap-30" mb="16px">
                    <Box width="80%">
                        <Box className="default-filter-text" color={isEditing ? 'gray' : '#3D3D3D'}>
                            Filter my booked slots by: &nbsp;&nbsp;
                            <Box className='fl-rb'>
                                {renderCheckbox('sixty', '60 mins', interviewDuration?.sixty)}
                                {renderCheckbox('ninety', '90 mins', interviewDuration?.ninety)}
                                {renderCheckbox('oneTwenty', '120 mins', interviewDuration?.oneTwenty)}
                            </Box>
                        </Box>
                        <Box className="default-count-helper-text fl-rb">
                            For any new entry, set default maximum no. of interviews in a day be,
                            <span className='fl-row fl-item-cntr'>
                                <span className="max-count-label-highlighted">&nbsp;Max. :</span>
                                <span>{renderDropdownParent()}</span>
                            </span>
                            <Box display="flex">
                                {isMaxCountLoaderVisible && <Box display="flex">
                                    <CircularProgress size={20} className='circularprogress' />
                                    <Typography className="progress-text">Updating default max count...</Typography>
                                </Box>}
                            </Box>
                        </Box>
                    </Box>
                    <Box width="80%">
                        <ul>
                            <li className="default-count-instruction-list-item">Interviews will only be able to schedule within the hours on your availability calendar.</li>
                            <li className="default-count-instruction-list-item">Click and drag below to select the times you are available.</li>
                            <li className="default-count-instruction-list-item">Your availability shall be optimized with 30 mins gaps between two interviews.</li>
                        </ul>
                    </Box>
                </Box>
                <div className="fl-row fl-sp-bw fl-item-cntr mb-3 table-legend">
                    <div>
                        <p className="m-reset-0 timezone-text"> Timezone: {timeZone}</p>
                    </div>
                    <Box className="fl-row fl-item-cntr" fontSize='12px'>
                        <p className="m-reset-0 mr-4"><span className="slot-maxedOut mr-2"></span> Maxed-out Slots</p>
                        <p className="m-reset-0 mr-4"><span className="slot-avail mr-2"></span>Selected Slots</p>
                        <p className="m-reset-0 mr-4"><span className="slot-booked mr-2"></span>Booked Slots</p>
                        <p className="m-reset-0"><span className="slot-empty mr-2"></span>Not Available Slots</p>
                    </Box>
                </div>

                {/* Table directional FABs */}
                {/* X-axis */}
                <Fab color="primary" className={cx("table-fab-x", xAxisCurrentDir)} onClick={(e) => { setXAxisCurrentDir((prev) => (prev === 'left' ? "right" : "left")) }} size="medium">
                    {xAxisCurrentDir === "right" ?
                        <LeftArrowIcon /> :
                        <RightArrowIcon />
                    }
                </Fab>

                {/* Y-axis */}
                <Box className="table-fab-container">
                    <Fab color="primary" onClick={(e) => {
                        handleYFabClick("up")
                    }} size="large">
                        <UpArrowIcon />
                    </Fab>

                    <Fab color="primary" onClick={(e) => {
                        handleYFabClick("down")
                    }} size="large">
                        <DownArrowIcon />
                    </Fab>
                </Box>
 
                <div className = {`card ${ enableSlot ? '' : 'card-disabled'}`}>
                    <div className = 'table-body mb-0' ref={tableRef}>
                     {renderDraggableTable()}
                    </div>
                </div>
                </> :
                    <div className="mt-7">
                        <ErrorOutlineIcon className="horizontal-center font-size-100" />
                        <p className="text-center font-size-lg">Select your timezone from personal info to update your availability.</p>
                    </div>
                }
            </PageView> 
        </div>   
    )
}

export default Availability




const formatTableTime = (index) => {
    if(index === 24) return '12:00 PM'
    if(index === 25) return '12:30 PM'
    if(index > 24){
        if(index%2 === 0) return formatNumber((index/2) - 12) + ':00 PM'
        else return formatNumber((index/2-0.5)-12) + ':30 PM'
    } else{
        if(index%2 === 0) return formatNumber(index/2)  + ':00 AM'
        else return formatNumber(index/2-0.5) + ':30 AM'
    }
}

const formatNumber = (num) => num < 10 ? '0' + num : num




const checkValiditiy = (val) => {
    for(let i=0; i<val.length; i++){
        let data = [];
        val[i]?.forEach(item=>{
            if(typeof item === 'object' && item !== null) {
                data.push(true);
            }
            else {
                data.push(item);
            }
        })
        const string = data.join('-')
        if(string.includes('false-true-false')) return false;
        if(val[i][0] && !val[i][1]) return false;
        if(!val[i][28] && val[i][29]) return false;
    }
    return true;
}



export const getAvailability = (array, start) => {
    let temp = []
    for(let i=0; i<array.length; i++){
        if(array[i]){
            if(temp.length === 0){
                temp.push(1)
            } else{
                if(temp[temp.length-1] <0){
                    temp.push(1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] + 1
                }
            }
        } else{
            if(temp.length === 0){
                temp.push(-1)
            } else{
                if(temp[temp.length-1] > 0){
                    temp.push(-1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] - 1
                }
            }
        }
    }
    const time = []
    let start1 = start*2
    for(let i=0; i<temp.length; i++){
        if(temp[i] > 0){
            time.push({start: formatTime(start1), end: formatTime(start1+temp[i])})
            start1 = start1+temp[i]
        } else{
            start1 = start1 + (temp[i]*-1)
        }
    }
    return time

}


const formatTime = (index) => {
    if(index%2 === 0) return formatNumber(index/2)  + ':00'
    else return formatNumber(index/2-0.5) + ':30'
}




const timeToIndex = (time) => {
    const a1 = parseInt(time.slice(0, 2))
    const a2 = parseInt(time.slice(3, 5)) === 30 ? 1 : 0
    const index = (a1*2 + a2) - 16
    return index
}

const convertMergedIntoArray = (data) => {
    const temp1 = Array(30).fill(false);
    for(let i=0; i<data.length; i++){
        for(let j=data[i].startIndex; j<data[i].endIndex; j++){
            if(data[i]?.data){
                temp1[j] = data[i];
            }
        }
    }
    return [false, ...temp1];
}

const convertIntoArray = (data, childCounts, tempMaxedSlot) => {
    const temp1 = Array(30).fill(false);
    for(let i=0; i<data.length; i++){
        for(let j=data[i].startIndex; j<data[i].endIndex; j++){
            if(data[i]?.data){
                let key = data[i].data.start_date;
                let updatedData = {...data[i], data: {...data[i]?.data, isMaxed: Number(tempMaxedSlot?.[key]) >= Number(childCounts?.[key])}};
                temp1[j] = updatedData;
            }
            else {
                temp1[i] = data[i];
            }
        }
    }
    return [false, ...temp1];
}

const convertBookedIntoArray = (data) => {
    const temp1 = Array(30).fill(false);
    for(let i=0; i<data.length; i++){
        for(let j=data[i].startIndex; j<data[i].endIndex; j++){
            temp1[j] = true;
        }
        temp1[data[i].startIndex] = {...data[i], data: {...data[i]?.data, isBooked: true}};

    }
    return [false, ...temp1];
}


const convertDataToCells = (data, childCounts, tempMaxedSlot, type) => {
    let firstLevelData = days
        .map(item2 => data.filter(item => {
            return item.start_date === moment(item2).format('DD-MM-YYYY')
        })
        .map(item => ({
            startIndex: timeToIndex(item.start_time), 
            endIndex: timeToIndex(item.end_time),
            data: item
        }))
        .sort((a, b) => a.startIndex - b.startIndex));

        let secondLevelDat = [];
        if(type === "booked"){
            secondLevelDat = firstLevelData.map(item => convertBookedIntoArray(item));
        }
        else if(type === "merged") 
        secondLevelDat = firstLevelData.map(item => convertMergedIntoArray(item));
         else {
            secondLevelDat = firstLevelData.map(item => convertIntoArray(item, childCounts, tempMaxedSlot));
        }
    return ([Array(31).fill(false), ...secondLevelDat])
    
}


const transposeArray = (data) => data[0].map((_, colIndex) => data.map(row => row[colIndex]));